<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.bsnDate }}
            </div>
            <ul class="content">
              <li class="item date">
                <input-date
                  format="YYYY-MM-DD"
                  type="lookup-condition"
                  v-model="searchConditions.bsnDate"
                  @change="onBsnDateChange"
                />
              </li>
              <li class="item text">
                ({{ searchConditions.bsnDayOfTheWeek }})
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.storeCode }}
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                    v-model="searchConditions.storeCodes"
                    cssClass="lookup-condition-multiselect"
                    :dataSource="searchOptions.storeCodeOptions"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="240"
                />
              </li>
            </ul>
          </li>
          <li class="field visitor">
            <div class="title">
              {{ labels.searchKeyword }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.searchKeyword"
                  @focus="onSearchValueClear"
                  @keydown.enter.native="onSearchPopupClicked"
                />
              </li>
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        button-div="GET"
                        @click.native="onSearchPopupClicked"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item check">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="1"
                        v-model="searchConditions.selFlag"
                        :disabled="
                          !searchConditions.grpNo && searchConditions.chkinId
                        "
                      />
                      <i></i>
                      <div class="label">단체</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="3"
                        v-model="searchConditions.selFlag"
                      />
                      <i></i>
                      <div class="label">팀</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        value="2"
                        v-model="searchConditions.selFlag"
                      />
                      <i></i>
                      <div class="label">개인</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
            button-div="GET"
            :is-shortcut-button="true"
            :ignore="isPopupOpened"
            @click.native="onViewTeamSales"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">전표 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li>
                  <erp-button
                      @click.native="onViewSettlementAccount"
                      :is-icon-custom="true">
                    정산내역서
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                  >인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="teamSalesGrid"
                gridLines="Both"
                :dataSource="teamSalesInfoList"
                :groupSettings="groupSettings"
                width="100%"
                height="100%"
                :allowGrouping="true"
                :allowResizing="true"
                :allowExcelExport="true"
                :allowPdfExport="true"
                :allowPaging="true"
                :pageSettings="teamSalesPageSettings"
                :columns="teamSalesGridColumns"
                :aggregates="teamSalesGridAggregates"
                :provides="teamSalesGridProvides"
                @queryCellInfo="queryCellInfo"
                :rowHeight="24"
                @actionComplete="teamSalesGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <pay-receipt-popup
      v-if="isPayReceiptPopupOpen"
      ref="payReceiptPopup"
      @popupClosed="payReceiptPopupClosed"
    />
    <find-visit-popup
      ref="findVisitPopup"
      v-if="isFindVisitPopupOpen"
      @popupClosed="onFindVisitPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.visitor .content .item.input {width: 120px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import { DATE_FORMAT_YYYY_MM_DD, getFormattedDate } from "@/utils/date";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName, commonCodesGetSortNo,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import { getStatusByTeamInfo } from "@/api/salesManagement";
import { orderBy as _orderBy } from "lodash";
import ErpButton from "@/components/button/ErpButton.vue";

import {
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  PdfExport,
  Page,
} from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import findVisitPopup from "@/views/common/FindVisitPopup";
import payReceiptPopup from "@/views/common/PayReceiptPopup";
import InputDate from "@/components/common/datetime/InputDate";
import GolfERPService from "@/service/GolfERPService";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { mapGetters } from "vuex";
import moment from "moment";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ReportView from "@/components/common/report/ReportView";


export default {
  name: "TeamSalesStatus",
  components: {
    InputText,
    EjsGridWrapper,
    InputDate,
    findVisitPopup,
    payReceiptPopup,
    ReportView,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  async created() {
    const queryBsnDate = this.$route.query.bsnDate;

    const nowMoment = await GolfERPService.fetchNow();
    this.now = nowMoment.toDate();

    this.searchConditions.bsnDate = queryBsnDate
      ? this.searchConditions.bsnDate = moment(queryBsnDate).format(DATE_FORMAT_YYYY_MM_DD)
      : this.searchConditions.bsnDate = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);

    if (this.searchConditions.searchKeyword) {
      this.onSearchPopupClicked();
    }

    this.searchConditions.bsnDayOfTheWeek = commonCodesGetComName(
        "DW_CODE",
        String(new Date().getDay() + 1)
    );
  },
  mounted() {
    const queryStoreCode = this.$route.query.storeCode;
    if (queryStoreCode) {
      this.searchConditions.storeCodes = [queryStoreCode];
    }
    const querySearchKeyword = this.$route.query.searchKeyword;
    if (querySearchKeyword) {
      this.searchConditions.searchKeyword = querySearchKeyword;
    }
  },
  data() {
    return {
      count: 0,

      teamSalesInfoListLength: 0,
      now: null,

      teamSalesPageSettings: { pageSize: 50 },

      labels: {
        bsnDate: "영업일자",
        storeCode: "매장",
        searchKeyword: "예약자/내장객명",
        selFlag: "내장구분",
      },

      queryCellInfo(args) {
        const {
          cell,
          column: { field, type },
          data,
        } = args;

        if (field === "totAmt") {
          cell.style.fontWeight = "bold"; // 총금액 컬럼 폰트굵기 css지정
        }
        if (field === "payFlag" && data.payFlag) {
          // 정산여부 폰트색상 css지정
          cell.style.backgroundColor = "#FF9999";
        }
        if (type.toLowerCase() === "number" && !data[field]) {
          cell.innerText = "-";
        }
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      teamSalesInfoList: [],
      searchOptions: {
        storeCodeOptions: commonCodesGetCommonCode("STORE_CODE"),
        dwCodeOption: commonCodesGetCommonCode("DW_CODE"),
      },
      isFindVisitPopupOpen: false,
      isPayReceiptPopupOpen: false,
      searchConditions: {
        bsnDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
        bsnDayOfTheWeek: null,
        searchKeyword: null,
        storeCodes: [],
        selFlag: 1, // 단체(default) 1, 팀 3, 개인 2
        chkinId: null, // 체크인ID
        visitId: null, // 내장객ID
        grpNo: null, // 단체번호
        grpName: null, // 단체명
        grpKind: null, // selFlag 단체/팀/개인 중 개인이면 null 아님
      },
      teamColumns: [
        {
          headerText: "시간",
          field: "startTime",
          width: "80",
          textAlign: "center",
          type: "string",
        },
        {
          headerText: "코스",
          field: "startCourse",
          width: "80",
          textAlign: "center",
          type: "string",
        },
        {
          headerText: "부구분",
          field: "partDiv",
          width: "90",
          textAlign: "center",
          type: "string",
        },
        {
          headerText: "단체명",
          field: "grpName",
          width: "180",
          textAlign: "left",
          type: "string",
        },
      ],
      visitColumns: [
        {
          headerText: "순번",
          field: "visitSno",
          width: "80",
          textAlign: "center",
          type: "number",
          format: "N",
        },
        {
          headerText: "내장객",
          field: "visitName",
          width: "90",
          textAlign: "left",
          type: "string",
        },
        {
          headerText: "락카",
          field: "lockerNo",
          width: "80",
          textAlign: "center",
          type: "string",
        },
      ],
      productColumns: [
        { headerText: "상품명", field: "productName", width: "180" },
        {
          headerText: "단가",
          field: "salePrice",
          width: "90",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "수량",
          field: "saleQty",
          width: "90",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "할인율",
          field: "dcRate",
          width: "90",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "할인금액",
          field: "dcAmt",
          width: "90",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      amtColumns: [
        {
          headerText: "총금액",
          field: "totAmt",
          width: "90",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "공급가액",
          field: "netAmt",
          width: "90",
          textAlign: "right",
          type: "number",
          format: "N",
        },
        {
          headerText: "부가세",
          field: "vatAmt",
          width: "90",
          textAlign: "right",
          type: "number",
          format: "N",
        },
      ],
      groupSettings: { showDropArea: false, columns: ["groupInfo"] },

      visiblePrintPaper: false,
    };
  },
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return this.isPayReceiptPopupOpen || this.isFindVisitPopupOpen;
    },
    datetimeString() {
      return moment(this.now).format(`YYYY-MM-DD HH:mm:ss`);
    },
    teamSalesGridColumns() {
      return [
        {
          field: "groupInfo",
          headerText: "구분",
          visible: false,
        },
        {
          field: "bsnDate",
          headerText: "영업일자",
          visible: false,
        },
        {
          field: "dwCode",
          headerText: "요일",
          visible: false,
        },
        {
          field: "bsnCode",
          headerText: "구분",
          visible: false,
        },
        {
          field: "storeCode",
          headerText: "매장",
          visible: false,
        },
        {
          allowEditing: false,
          columns: this.teamColumns,
          headerText: "팀정보",
          textAlign: "Center",
          type: "string",
        },
        {
          allowEditing: false,
          columns: this.visitColumns,
          headerText: "내장객정보",
          textAlign: "Center",
          type: "string",
        },
        {
          allowEditing: false,
          columns: this.productColumns,
          headerText: "상품정보",
          textAlign: "Center",
          type: "string",
        },
        {
          allowEditing: false,
          columns: this.amtColumns,
          headerText: "판매금액",
          textAlign: "Center",
          type: "number",
          format: "N",
        },
        {
          allowEditing: false,
          displayAsCheckBox: true,
          editType: "booleanedit",
          field: "payFlag",
          headerText: "정산여부",
          textAlign: "Center",
          type: "boolean",
          width: 100,
        },
        {
          allowEditing: false,
          displayAsCheckBox: true,
          editType: "booleanedit",
          field: "caddieUseFlag",
          headerText: "캐디이용",
          textAlign: "Center",
          type: "boolean",
          width: 100,
        }
      ];
    },
    teamSalesGridAggregates() {
      return [
        {
          columns: [
            {
              field: "productName",
              aggregationType: "GroupCaption",
              customAggregate: (aggregates) => {
                const sampleItem = aggregates.items[0];
                return sampleItem.groupInfo;
              },
            },
            {
              field: "salePrice",
              aggregationType: "GroupCaption",
              customAggregate: "소계",
            },
            {
              field: "saleQty",
              aggregationType: "GroupSum",
            },
            {
              field: "dcRate",
              aggregationType: "GroupSum",
            },
            {
              field: "dcAmt",
              aggregationType: "GroupSum",
            },
            {
              field: "totAmt",
              aggregationType: "GroupSum",
            },
            {
              field: "netAmt",
              aggregationType: "GroupSum",
            },
            {
              field: "vatAmt",
              aggregationType: "GroupSum",
            },
          ],
        },
      ];
    },
    teamSalesGridProvides() {
      return [Aggregate, Filter, Resize, Group, ExcelExport, PdfExport, Page];
    },
  },
  methods: {
    numberWithCommas,
    courseCodeValueAccess(field, data) {
      let courseCode = data[field];
      if (courseCode != null) {
        courseCode = commonCodesGetComName("COURSE_CODE", courseCode);
      }
      return courseCode;
    },
    partDivValueAccess(field, data) {
      return data[field] + "부";
    },
    onBsnDateChange(args) {
      console.log(args);
      if (args.data == null) {
        this.searchConditions.bsnDayOfTheWeek = null;
      } else {
        this.searchConditions.bsnDayOfTheWeek = commonCodesGetComName(
          "DW_CODE",
          String(new Date(args.data).getDay() + 1)
        );
      }
    },
    onSearchValueClear() {
      this.searchConditions.chkinId = null;
      this.searchConditions.visitId = null;
      this.searchConditions.grpNo = null;
      this.searchConditions.grpName = null;
      this.searchConditions.selFlag = 1;
      this.searchConditions.searchKeyword = null;
    },
    onSearchPopupClicked() {
      this.openCheckinVisitsFindPopup();
    },
    onViewTeamSales() {
      if (!this.isValidBsnDate()) {
        return;
      }
      if (!this.isValidSearchKeyword()) {
        return;
      }
      this.getStatusByTeamInfo();
    },
    isValidBsnDate() {
      if (!this.searchConditions.bsnDate) {
        this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["영업일자"])
        );
        return false;
      }

      return true;
    },
    isValidSearchKeyword() {
      if (!this.searchConditions.visitId || !this.searchConditions.chkinId) {
        this.errorToast("내장객 혹은 락카번호 혹은 캐디명을 검색해 주세요");
        return false;
      }
      return true;
    },
    openCheckinVisitsFindPopup() {
      this.isFindVisitPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.findVisitPopup.showFindVisitPopup({
          bsnDate: getFormattedDate(this.searchConditions.bsnDate),
          resveVisitName: this.searchConditions.searchKeyword,
          resveVisitFlag: false, // 예약만 하고 내장하지 않은 예약자만 출력할 시 false
        });
      });
    },
    onFindVisitPopupClosed(event) {
      this.isFindVisitPopupOpen = false;
      if (event && event.selectRowData) {
        this.searchConditions.searchKeyword = event.selectRowData.resveName;

        this.searchConditions.chkinId = event.selectRowData.chkinId; // 체크인ID
        this.searchConditions.visitId = event.selectRowData.visitId; // 내장객ID
        this.searchConditions.grpNo = event.selectRowData.grpNo; // 단체번호
        this.searchConditions.grpName = event.selectRowData.grpName; // 단체명
        if (event.selectRowData.grpNo && event.selectRowData.grpName) {
          // 단체번호, 단체명이 존재할때 단체 버튼에 체크
          this.searchConditions.selFlag = 1;
        } else {
          // 존재하지 않는 경우 무조건 default인 팀 버튼에 체크(개인에 체크하는 것은 유저가 선택할 때)
          this.searchConditions.selFlag = 3;
        }
        this.getStatusByTeamInfo();
      }
    },
    onViewSettlementAccount() {
      let selectedRecords = this.$refs.teamSalesGrid.getSelectedRecords();
      if (selectedRecords.length === 0) {
        this.errorToast("내장객을 선택해 주십시오");
        return;
      }

      this.isPayReceiptPopupOpen = true;
      const popupData = {
        bsnDate: selectedRecords[0].bsnDate,
        chkinId: selectedRecords[0].chkinId,
        grpNo: selectedRecords[0].grpNo,
        grpName: selectedRecords[0].grpName,
        startTime: selectedRecords[0].startTime,
        startCourse: selectedRecords[0].startCourse,
        visitName: selectedRecords[0].visitName,
        lockerNo: selectedRecords[0].lockerNo,
        visitId: selectedRecords[0].visitId,
      };
      console.log("popupData.===>", popupData);

      this.$nextTick(() => {
        this.$refs.payReceiptPopup.showPayReceiptPopup(popupData);
      });
    },
    payReceiptPopupClosed() {
      this.isPayReceiptPopupOpen = false;
    },
    setGroupInfo(salesReportByTeamList) {
      //그룹별 데이터 출력을 위해 groupInfo 생성
      this.teamSalesInfoList = _orderBy(salesReportByTeamList.map((item) => {
        item.courseCodeSortNo = commonCodesGetSortNo("COURSE_CODE", item.startCourse);
        item.startCourse = commonCodesGetComName(
          "COURSE_CODE",
          item.startCourse
        );
        item.partDiv = commonCodesGetComName("PART_DIV", item.partDiv);

        const dwCode = commonCodesGetComName("DW_CODE", item.dwCode);
        const bsnCode = commonCodesGetComName("BSN_CODE", item.bsnCode);
        const storeCode = commonCodesGetComName("STORE_CODE", item.storeCode);

        item.groupInfo = `${item.bsnDate} (${dwCode}) ${bsnCode} ${storeCode}`;
        return item;
      }), ["storeCode", "startTime", "courseCodeSortNo", "visitSno", "sno"]);
    },
    getStatusByTeamInfo() {
      const bsnDate = this.searchConditions.bsnDate;
      const chkinId = this.searchConditions.chkinId;
      const grpName = this.searchConditions.grpName;
      const grpNo = this.searchConditions.grpNo;
      const selFlag = this.searchConditions.selFlag;
      const visitId = this.searchConditions.visitId;
      const storeCodes =
        this.searchConditions.storeCodes.length > 0
          ? this.searchConditions.storeCodes
          : null;

      getStatusByTeamInfo(
        bsnDate,
        chkinId,
        grpName,
        grpNo,
        selFlag,
        storeCodes,
        visitId
      )
        .then((response) => {
          this.setGroupInfo(response.value.salesReportByTeamList);

          this.teamSalesInfoListLength =
            response.value.salesReportByTeamList.length;
        })
        .catch((error) => {
          console.error("getStatusByTeamInfo.err ===>", error);
        });
    },
    teamSalesGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.teamSalesGrid?.getGridBatchCount() || 0
      );
    },
    print: function () {
      const result = this.$refs.teamSalesGrid.getGridBatchData();
      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const selFlag = ["", "단체", "개인", "팀"];

      const searchOptionsList = [
        {
          key: this.labels.bsnDate,
          value: this.searchConditions.bsnDate,
        },
        {
          key: this.labels.storeCode,
          value: this.searchConditions.storeCodes
            .map(item => commonCodesGetComName("STORE_CODE", item)),
        },
        {
          key: this.labels.searchKeyword,
          value: this.searchConditions.searchKeyword,
        },
        {
          key: this.labels.selFlag,
          value: selFlag[this.searchConditions.selFlag],
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onClickExcel() {
      this.$refs.teamSalesGrid.excelExport();
    },
  },
};
</script>
